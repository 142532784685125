import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  styled,
} from "@mui/material";

import { getData } from "../services/Request";
import { useLoader } from "../context/LoaderContext";

const rowsPerPageOptions = [10, 20, 30];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 20,
  backgroundColor: "#61ED66",
  color: theme.palette.common.white,
}));

const Payment = () => {
  const { showLoader, hideLoader } = useLoader();
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  useEffect(() => {
    getAllPayments();
  }, []);

  const getAllPayments = () => {
    showLoader();
    getData("mpesa/detail")
      .then((response) => {
        setPayments(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = payments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#52057B !important",
              }}
            >
              <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Name</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Contact</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Currency</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Amount</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>PaymentStatus</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>TransactionId</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>GroupPlanId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.map((row, index) => (
              <TableRow key={row.id} hover>
                <TableCell>{index + 1}</TableCell>

                <TableCell>{row.name}</TableCell>
                <TableCell>{row.phoneNumber}</TableCell>
                <TableCell>{row.currency}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.paymentStatus}</TableCell>
                <TableCell>{row.transactionId}</TableCell>
                <TableCell>{row.groupPlanId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={payments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Payment;
