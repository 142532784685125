import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  styled,
} from "@mui/material";

import { getData } from "../services/Request";
import { useLoader } from "../context/LoaderContext";

const rowsPerPageOptions = [10, 20, 30];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 20,
  backgroundColor: "#61ED66",
  color: theme.palette.common.white,
}));

const Groups = () => {
  const { showLoader, hideLoader } = useLoader();
  const [groups, setGroups] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = () => {
    showLoader();
    getData("group/all")
      .then((response) => {
        setGroups(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = groups.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#52057B !important",
              }}
            >
              <TableCell sx={{ color: "#ffffff" }}>#</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Image</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Title</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Description</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>GroupPlanId</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Amount</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.map((row, index) => (
              <TableRow key={row.id} hover>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <img
                    src={row.groupImage}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  ></img>
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.groupPlanId}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.fee}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={groups.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Groups;
